@import '../../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.googleFormField {
  width: 100%;
  margin-top: 24px;
}

.discription{
  margin: 0;
  font-size: 14px
}

.heading{
  margin-top:24px;
}

.error {
  color: var(--failColor);
}

.errorMessage {
  margin-top: 24px;
  color: var(--failColor);
}

.billingDetails {
  margin-bottom: 48px;
}

.billingHeading {
  margin: 0 0 12px 0;
  color: var(--matterColor);

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  border-bottom-color: var(--matterColorAnti);
}

.submitContainer {
  margin-top: auto;

  @media (--viewportLarge) {
    margin-top: 72px;
  }
}

.submitButton {
  margin-top: 22px;

  @media (--viewportMedium) {
    margin-top: 26px;
  }
  @media (--viewportLarge) {
    margin-top: 16px;
  }
}
